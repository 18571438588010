<template>
  <div>
    <div ref="indicators" class="indicators flex-center">
      <div :style="{ width: '300px' }" class="indicator">
        <div class="progress" />
      </div>
      <div
        v-for="mediaItem in mediaItems.length"
        :key="mediaItem"
        :style="{ width: `${300 / mediaItems.length}px` }"
        class="indicator"
      >
        <div class="progress" />
      </div>
    </div>
    <div class="slider current-advert-preview__attachments">
      <ElCarousel
        ref="carousel"
        v-model="currentSlide"
        :autoplay="false"
        height="600px"
        @change="onPageChange"
      >
        <ElCarouselItem v-for="(mediaItem, index) in mediaItems" :key="`${mediaItem.uri} ${index}`">
          <AdvertPreviewAttachment :uri="mediaItem.uri" :mime-type="mediaItem.mimeType" />
        </ElCarouselItem>
      </ElCarousel>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ElCarousel, ElCarouselItem } from 'element-plus';
import { type MediaGalleryItem } from '~/interfaces/advert-studio';

const transitionDelay = 300;

interface Props {
  mediaItems: MediaGalleryItem[];
}

const props = defineProps<Props>();

/* eslint-disable no-undef */
const indicators = ref();
const carousel = ref();
const currentSlide = ref(1);

let indicatorSlide = 1;

const workingIntervals: (() => void)[] = [];

const parseIntFromStyle = (payload: string) => +(payload || '0px').slice(0, -2);

const resetProgressDivWidth = (progress: HTMLDivElement) => {
  progress.style.width = '0px';
};

const resetIndicatorsTransition = () => {
  const { children } = unref(indicators);
  [...children].forEach((progress) => {
    const { style } = progress.children[0];
    style.transition = 'ease-in 0s';
    setTimeout(() => {
      style.transition = `ease-in ${transitionDelay}ms`;
    }, transitionDelay * 3);
  });
};

const clearWorkingIntervals = () => {
  workingIntervals.forEach((clearWorkingInterval) => clearWorkingInterval());
};

const getCurrentIndicatorAnimationParams = () => {
  const { children } = unref(indicators);
  const currentIndicator = children[indicatorSlide - 1] ?? children[0];

  const targetWidth = parseIntFromStyle(getComputedStyle(currentIndicator).width);
  const delay = (targetWidth * ((props.mediaItems.length || 1) + 1)) / 4;
  const increaseWidthFor = targetWidth / (15e3 / delay);
  return { currentIndicator, targetWidth, delay, increaseWidthFor };
};

const initFillStoryIndicator = () =>
  new Promise((resolve) => {
    if (!unref(carousel)) return;
    const { currentIndicator, targetWidth, delay, increaseWidthFor } =
      getCurrentIndicatorAnimationParams();

    const progress = currentIndicator.children[0];
    resetProgressDivWidth(progress);

    const interval = setInterval(() => {
      workingIntervals.push(() => {
        clearInterval(interval);
      });

      const currentWidth = parseIntFromStyle(progress.style.width || '0px');

      const newWidth = currentWidth + increaseWidthFor;
      progress.style.width = `${newWidth}px`;

      if (newWidth > targetWidth) {
        clearInterval(interval);
        resolve(true);
      }
    }, delay);
  });

const resetIndicatorsProgress = () => {
  [...unref(indicators).children].forEach((indicator) => {
    const progress = indicator.children[0];
    resetProgressDivWidth(progress);
  });
};

const toNextSlide = () => {
  unref(carousel).setActiveItem(indicatorSlide);
  indicatorSlide += 1;
};
const toFirstSlide = () => {
  unref(carousel).setActiveItem(0);
  indicatorSlide = 1;
  resetIndicatorsProgress();
};

const initFillStoryIndicatorAndMoveCarousel = () => {
  initFillStoryIndicator().then(() => {
    if (!unref(carousel)) return;
    if (indicatorSlide !== props.mediaItems.length) {
      toNextSlide();
    } else {
      toFirstSlide();
    }

    initFillStoryIndicatorAndMoveCarousel();
  });
};

const setProgresses = () => {
  const currentSlideIndex = indicatorSlide - 1;

  const allIndicators = [...unref(indicators).children];
  allIndicators.slice(0, currentSlideIndex).forEach((previousIndicator) => {
    previousIndicator.children[0].style.width = '999px';
  });
  allIndicators.slice(currentSlideIndex + 1).forEach((nextIndicator) => {
    nextIndicator.children[0].style.width = '0px';
  });
};

const onPageChange = (slide: number) => {
  resetIndicatorsTransition();
  clearWorkingIntervals();
  indicatorSlide = slide + 1;
  setProgresses();
  initFillStoryIndicatorAndMoveCarousel();
};

const initStoryPreviewInteractive = () => {
  if (!unref(carousel)) return;
  if (workingIntervals.length) {
    clearWorkingIntervals();
    resetIndicatorsTransition();
    toFirstSlide();
  }
  // if (contentType !== AdvertContentType.media_gallery) {
  //   initFillStoryIndicator();
  // } else {
    initFillStoryIndicatorAndMoveCarousel();
  //}
};

onMounted(initStoryPreviewInteractive);

watch(
  computed(() => [props.mediaItems]),
  () => initStoryPreviewInteractive(),
);
</script>
<style lang="scss">
.current-advert-preview-inner.story {
  padding: 0;
  overflow: hidden;
  border-radius: 24px;
  width: 320px;
  margin: auto;

  > div {
    position: relative;

    .indicators {
      position: absolute;
      z-index: 10;
      width: 95vw;
      top: 3%;
      margin: auto;
      left: 2%;
      gap: 4px;

      > .indicator {
        width: 5.5%;
        height: 4px;
        background: rgba($white, 0.5);
        border-radius: 1000px;
        overflow: hidden;

        .progress {
          transition: ease-in 300ms;
          background: $white;
          border-radius: 1000px;
          height: 4px;
          width: 0px;
        }
      }
    }
  }

  .el-carousel__arrow {
    display: none;
  }

  .el-carousel__container {
    width: 420px;
    margin: auto;
  }

  .slider {
    margin-top: 0;

    .media-item {
      border-radius: 0;
      width: 320px;
      height: 100%;
      background: rgb(2, 59, 97);

      > img {
        object-fit: contain;
        width: 320px;
        height: 517px;
      }
    }
  }
}

.el-carousel__indicator {
  display: none;
}
</style>
