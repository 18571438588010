<template>
  <div class="flex-center media-item" :class="imageAttachment ? '' : 'video-wrapper'">
    <img v-if="imageAttachment" :src="uri" />
    <UIVideo v-else :uri="uri" />
  </div>
</template>
<script setup lang="ts">
import { isImage } from '~/bin/advert-studio/helpers';

interface Props {
  uri: string;
  mimeType: string;
}

const props = defineProps<Props>();

const imageAttachment = isImage(props.mimeType);
</script>
