<template>
  <div
    v-if="mediaItems.length"
    :class="`media-items-${mediaItems.length} ${gorizontalClassName}`"
    class="current-advert-preview__attachments"
  >
    <AdvertPreviewAttachment
      v-for="mediaItem in mediaItems"
      :key="mediaItem.uri"
      :uri="mediaItem.uri"
      :mime-type="mediaItem.mimeType"
    />
  </div>
</template>
<script setup lang="ts">
import useAwaitRefComeTrue from '~/composables/UI/useAwaitRefComeTrue';
import { type MediaGalleryItem } from '~/interfaces/advert-studio';

interface Props {
  mediaItems: MediaGalleryItem[];
}

const props = defineProps<Props>();

/* eslint-disable no-undef */
const gorizontalClassName = ref('');

const setGorizontalMediaItemsOrientation = async () => {
  if (!props.mediaItems.length) {
    await useAwaitRefComeTrue(computed(() => props.mediaItems.length));
  }
  const firstImage = new Image();
  firstImage.src = (props.mediaItems.at(0) as MediaGalleryItem).uri;
  firstImage.onload = () => {
    gorizontalClassName.value =
      firstImage.width / firstImage.height >= 1 ? 'gorizontal' : 'gorizontal';
  };
};

onBeforeMount(setGorizontalMediaItemsOrientation);
</script>

<style lang="scss">
$max-height: 350px;

.current-advert-preview__attachments {
  display: grid;
  flex-wrap: wrap;
  margin-top: 12px;
  gap: 5px;

  div.media-item {
    overflow: hidden;
    border-radius: 8px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      max-height: calc($max-height * 1.1);
    }

    &.single {
      width: 100%;
    }

    > video {
      width: 100%;
      height: 100%;
    }
  }

  .grow:not(.single) {
    width: 49%;
  }

  &:not(.gorizontal).media-items- {
    &2 {
      grid-template-columns: 1fr 1fr;

      > div {
        height: $max-height;
      }
    }

    &3 {
      grid-template-columns: 1fr 1fr 1fr;

      > div:first-child {
        grid-column: 1/3;
        grid-row: 1 / span 2;
        height: $max-height;
      }

      > div:not(:first-child) {
        height: calc($max-height / 2);
      }
    }

    &4 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, calc($max-height/3));

      > div:first-child {
        grid-row: 1 / span 3;
        grid-column: 1/-2;
      }
    }

    &5,
    &7,
    &8 {
      > div:first-child {
        grid-row: row;
        grid-column: col / span 2;
      }

      > div:nth-child(2) {
        grid-row: row;
        grid-column: col 3 / span 2;
      }

      > div {
        width: 100%;
        height: calc($max-height * 0.6);
      }
    }

    &5 > div:not(:first-child):not(:nth-child(2)),
    &7 > div:nth-child(5),
    &7 > div:nth-child(6),
    &7 > div:nth-child(7),
    &8 > div:not(:first-child):not(:nth-child(2)),
    &9 > div,
    &10 > div:nth-child(1),
    &10 > div:nth-child(2),
    &10 > div:nth-child(3),
    &10 > div:nth-child(8),
    &10 > div:nth-child(9),
    &10 > div:nth-child(10) {
      width: 110px;
      height: calc($max-height * 0.66);

      @media (max-width: 430px) {
        width: 28vw;
      }
    }

    &8 > div:nth-child(6),
    &8 > div:nth-child(7),
    &8 > div:nth-child(8),
    &7 > div:nth-child(5),
    &7 > div:nth-child(6),
    &7 > div:nth-child(7),
    &10 > div:nth-child(8),
    &10 > div:nth-child(9),
    &10 > div:nth-child(10) {
      grid-row: row 3;
    }

    // Первые в тройке
    &7 > div:nth-child(5),
    &5 > div:nth-child(3),
    &8 > div:nth-child(3),
    &8 > div:nth-child(6),
    &10 > div:nth-child(1),
    &10 > div:nth-child(8) {
      grid-column: col 1 / span 2;
    }

    // Вторые в тройке
    &7 > div:nth-child(6),
    &5 > div:nth-child(4),
    &8 > div:nth-child(4),
    &8 > div:nth-child(7),
    &10 > div:nth-child(2),
    &10 > div:nth-child(9) {
      grid-column: col 3 / span 2;
      transform: translateX(-58px);

      @media (max-width: 430px) {
        transform: translateX(-15vw);
      }
    }

    // Третьи в тройке
    &5 > div:nth-child(5),
    &7 > div:nth-child(7),
    &8 > div:nth-child(5),
    &8 > div:nth-child(8),
    &10 > div:nth-child(3),
    &10 > div:nth-child(10) {
      grid-column: col 4 / span 3;
      transform: translateX(-30px);
    }

    &5 {
      grid-template-rows: [row] auto [row] auto [row];

      @media (max-width: 430px) {
        grid-template-columns: repeat(4, [col] 21vw);
      }

      > div:nth-child(3),
      > div:nth-child(4),
      > div:nth-child(5) {
        grid-row: row 2;
      }
    }

    &6 {
      > div {
        height: calc($max-height/2);
      }

      > div:nth-child(1),
      > div:nth-child(4),
      > div:nth-child(6) {
        grid-row: 1 / 2;
      }

      > div:nth-child(1),
      > div:nth-child(2) {
        grid-column: 2 / 3;
      }

      > div:nth-child(2),
      > div:nth-child(3),
      > div:nth-child(5) {
        grid-row: 2 / 3;
      }

      > div:nth-child(4),
      > div:nth-child(5) {
        grid-column: 1 / 2;
      }

      > div:nth-child(3),
      > div:nth-child(6) {
        grid-column: 3 / 4;
      }
    }

    &7,
    &8,
    &10,
    &5 {
      grid-template-columns: repeat(4, [col] 80px);
    }

    &7,
    &8,
    &10 {
      grid-template-rows: [row] auto [row] auto [row] auto;

      @media (max-width: 430px) {
        grid-template-columns: repeat(4, [col] 78px);

        &10 {
          grid-template-columns: repeat(4, [col] 74px);
        }
      }
    }

    &7 {
      > div:nth-child(3),
      > div:nth-child(4) {
        grid-row: row 2;
      }

      > div:nth-child(3) {
        grid-column: col / span 2;
      }

      > div:nth-child(4) {
        grid-column: col 3 / span 2;
      }
    }

    &8 {
      > div:nth-child(3),
      > div:nth-child(4),
      > div:nth-child(5) {
        grid-row: row 2;
      }

      > div:nth-child(6),
      > div:nth-child(7),
      > div:nth-child(8) {
        grid-row: row 3;
      }
    }

    &10 {
      > div:nth-child(3),
      > div:nth-child(1),
      > div:nth-child(2) {
        grid-row: row 1;
      }
    }

    &9 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    &10 > div:nth-child(4),
    &10 > div:nth-child(6),
    &10 > div:nth-child(5),
    &10 > div:nth-child(7) {
      grid-row: row 2;
      height: calc($max-height * 0.33);
    }

    &10 > div:nth-child(4),
    &10 > div:nth-child(6),
    &10 > div:nth-child(5),
    &10 > div:nth-child(7) {
      width: 80px;

      @media (max-width: 430px) {
        width: 78px;
      }
    }

    &10 {
      > div:nth-child(9),
      > div:nth-child(8),
      > div:nth-child(10) {
        grid-row: row 3;
      }
    }
  }

  &.gorizontal.media-items- {
    &2 {
      grid-template-rows: 1fr 1fr;
    }

    &2 > div {
      height: calc($max-height/2);
    }

    &3 {
      grid-template-rows: 1fr 1fr;

      > div:first-child {
        grid-row: 1/3;
        grid-column: 1 / span 2;
        height: calc($max-height * 0.66);
      }

      > div:not(:first-child) {
        height: calc($max-height / 3);
      }
    }

    &3,
    &5 {
      > div:first-child {
        grid-row: 1/3;
        grid-column: 1 / span 2;
        height: calc($max-height * 0.66);
      }
    }

    &4 {
      grid-template-rows: calc($max-height * 0.6) calc($max-height / 3);

      > div:first-child {
        grid-row: 1/-2;
        grid-column: 1 / span 3;
      }
    }

    &5 {
      grid-template-rows: 1fr 1fr 1fr;
    }

    &6 {
      grid-template-columns: 1fr 1fr;
    }

    &7,
    &8,
    &9 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &7 {
      grid-template-rows: 2fr 1fr 1fr;

      > div:first-child {
        grid-column: 1/4;
      }
    }

    &8 > div:not(:first-child),
    &8 > div:not(:nth-child(2)),
    &9 > div:nth-child(7),
    &9 > div:nth-child(8),
    &9 > div:nth-child(9) {
      height: calc($max-height/4);
      width: clamp(100px, 27.3vw, 110px);
    }

    &8 > div:first-child,
    &8 > div:nth-child(2),
    &9 > div:nth-child(1),
    &9 > div:nth-child(2),
    &9 > div:nth-child(3),
    &9 > div:nth-child(4),
    &9 > div:nth-child(5),
    &9 > div:nth-child(6) {
      height: calc($max-height/2);
      width: clamp(155px, 40vw, 168px);
    }

    &8 {
      grid-template-rows: 2fr 1fr 1fr;
    }

    &8 > div:nth-child(1),
    &8 > div:nth-child(2),
    &9 > div:nth-child(1),
    &9 > div:nth-child(2),
    &10 > div:nth-child(1),
    &10 > div:nth-child(2) {
      grid-row: row 1;
    }

    &8 > div:nth-child(3),
    &8 > div:nth-child(4),
    &8 > div:nth-child(5),
    &9 > div:nth-child(3),
    &9 > div:nth-child(4),
    &10 > div:nth-child(3),
    &10 > div:nth-child(4) {
      grid-row: row 2;
    }

    &8 > div:nth-child(6),
    &8 > div:nth-child(7),
    &8 > div:nth-child(8),
    &9 > div:nth-child(5),
    &9 > div:nth-child(6),
    &10 > div:nth-child(5),
    &10 > div:nth-child(6),
    &10 > div:nth-child(7) {
      grid-row: row 3;
    }

    &9 > div:nth-child(7),
    &9 > div:nth-child(8),
    &9 > div:nth-child(9),
    &10 > div:nth-child(10),
    &10 > div:nth-child(8),
    &10 > div:nth-child(9) {
      grid-row: row 4;
    }

    &8 > div:nth-child(4),
    &8 > div:nth-child(7),
    &9 > div:nth-child(8),
    &10 > div:nth-child(6),
    &10 > div:nth-child(9) {
      transform: translateX(-58px);

      @media (max-width: 578px) {
        transform: translateX(-55px);
      }
    }

    &8 > div:nth-child(5),
    &8 > div:nth-child(8),
    &9 > div:nth-child(9),
    &10 > div:nth-child(7),
    &10 > div:nth-child(10) {
      transform: translateX(-115px);

      @media (max-width: 578px) {
        transform: translateX(-110px);
      }
    }

    &9 {
      grid-template-rows: 1.5fr 1.5fr 1.5fr 1fr;
    }

    &10 {
      grid-template-rows: 1.5fr 1.5fr 1fr 1fr;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: clamp(62px, 3vw, 66px);

      div:nth-child(1),
      div:nth-child(3) {
        grid-column: 1/3;
      }

      div:nth-child(2),
      div:nth-child(4) {
        grid-column: 2/4;
      }

      div:nth-child(1),
      div:nth-child(3),
      div:nth-child(2),
      div:nth-child(4) {
        width: clamp(156px, 30vw, 170px);
      }

      div:nth-child(5),
      div:nth-child(6),
      div:nth-child(7),
      div:nth-child(8),
      div:nth-child(9),
      div:nth-child(10) {
        height: calc($max-height/4);
        width: clamp(100px, 27.3vw, 110px);
      }
    }
  }
}
</style>
