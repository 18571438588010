<template>
  <div
    :class="storyAdvertType ? 'story' : ''"
    class="col-gap-3 current-advert-preview current-advert-preview-inner"
  >
    <AdvertPreviewStory
      v-if="storyAdvertType"
      :media-items="mediaItems"
    />
    <template v-else>
      <AdvertPreviewPost
        v-if="type === AdvertType.post"
        :text="text"
        :media-items="mediaItems"
        :buttons="buttons"
      />
      <AdvertPreviewRepost v-else-if="type === AdvertType.repost && repostUrl" :url="repostUrl" />
    </template>
  </div>
</template>
<script setup lang="ts">
import type { MediaGalleryItem, TelegramButton } from '~/interfaces/advert-studio';
import { AdvertType } from '~/interfaces/advert-studio';

interface Props {
  mediaItems?: MediaGalleryItem[] | undefined;
  buttons?: TelegramButton[] | undefined;
  text?: string | undefined;
  type: AdvertType;
  repostUrl?: string | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  mediaItems: () => [],
  buttons: () => [],
  text: '',
  repostUrl: '',
});

/* eslint-disable no-undef */

const storyAdvertType = computed(() => props.type === AdvertType.story);
</script>
<style lang="scss">
$max-height: 350px;

.current-advert-preview {
  &__text {
    .tg-spoiler {
      background: url(public/assets/img/white-glitch.gif);
      background-size: contain;
      color: $white;
    }

    code {
      font-family: monospace;
    }
  }

  &__text {
    font-size: 19px !important;
    font-weight: 300;
    white-space: pre-wrap;
    word-break: break-word;
    margin-top: 12px;
  }

  &__buttons {
    margin: 20px auto 0 auto;

    > a {
      position: relative;
      border-radius: 8px;
      background: #f8f8f8;
      color: $black;
      text-decoration: none;
      padding: 16px;
      font-weight: 400;
      height: 48px;
      word-break: break-word;
      text-align: center;
      width: 100%;

      svg {
        position: absolute;
        transform: rotate(45deg);
        left: 95%;
        top: 5%;
        width: 16px;
      }
    }
  }
}
</style>
