import { validateRequiredField } from '~/helpers/validators/field-validators';
import { AdvertType, Attachment, type Advert } from '~/interfaces/advert-studio';

export const advertTypeOptions = [
  {
    value: AdvertType.post,
    name: 'Пост',
  },
  {
    value: AdvertType.story,
    name: 'История',
  },
  {
    value: AdvertType.repost,
    name: 'Репост',
  },
];

export const advertTypeContentTypes = {
  [AdvertType.post]: {
    image: Attachment.image,
    video: Attachment.video,
    text: 'text',
    media_gallery: 'media_gallery',
  },
  [AdvertType.repost]: {
    repost: 'repost',
  },
  [AdvertType.unknown]: {
    unknown: 'unknown',
  },
  [AdvertType.story]: {
    image: Attachment.image,
    video: Attachment.video,
  },
};

export const mappedAdvertTypes = {
  [AdvertType.story]: 'история',
  [AdvertType.post]: 'запись',
  [AdvertType.repost]: 'репост',
};

export const mockAdvert: Advert = {
  name: 'Моковый креативозавр',
  social: 'telegram',
  content: {
    text: 'Старая великая сказка о возможностях добрых людей рассказывается детям для хорошей и долгой жизни',
    mediaItems: [
      { uri: '/assets/img/2.jpeg', mimeType: 'image/jpeg' },
      { uri: '/assets/img/3.jpeg', mimeType: 'image/jpeg' },
      { uri: '/assets/img/4.png', mimeType: 'image/jpeg' },
    ],
    buttons: [],
  },
  advertType: AdvertType.post,
  createdAt: '2024-01-26',
  id: 1,
};

export const INVALID_IMAGE_MESSAGE = {
  type: 'Прикрепленный файл не является картинкой допустимого формата (.jpg, .jpeg, .png, .gif )',
  size: 'Размер изображения превышает максимальный (5 Мб)',
};

export const SIZE_LIMIT = {
  ['video']: 25e6,
  ['image']: 5e6,
};

export const INVALID_VIDEO_MESSAGE = {
  type: 'Прикрепленный файл не является видео допустимого формата (mp4, mov, avi, wmv)',
  size: 'Максимальный размер загружаемого видео - 19 Мб',
};

export const orderNameFieldSettings = {
  inputLabel: 'Название заказа',
  inputName: 'order-name',
  placeholder: 'Укажите название',
  validatingFunction: validateRequiredField,
};

export const advertNameFieldSettings = {
  ...orderNameFieldSettings,
  inputLabel: 'Название креатива',
  inputName: 'advert-name',
};
