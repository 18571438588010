<template>
  <iframe
    v-if="source"
    title="Результат загрузки репоста"
    class="repost"
    frameBorder="none"
    :src="source"
  />
  <div v-else />
</template>
<script setup lang="ts">
interface Props {
  url: string;
}

const props = defineProps<Props>();
/* eslint-disable no-undef */
const source = computed(() => {
  try {
    const url = new URL(props.url);
    url.searchParams.append('embed', '1');
    return url.href;
  } catch (error) {
    return '';
  }
});
</script>
<style lang="scss" scoped>
iframe {
  width: 100%;
  pointer-events: auto;
  height: 60vh;
}
</style>
